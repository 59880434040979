import Layout from "@/layout";

const operate_manage_router_config = [
  {
    path: "/operate-center",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/operate-center/channel/list",
      //   component: () => import("@/views/operate-manage/channel/index.vue"),
      //   hidden: true,
      //   meta: { title: "单渠道利润率亏损限额", icon: "look" },
      // },
      // {
      //   path: "/operate-center/station/list",
      //   component: () => import("@/views/operate-manage/station/index.vue"),
      //   hidden: true,
      //   meta: { title: "单站单日累计亏损限额", icon: "look" },
      // },
      {
        path: "/operate-center/records/list",
        component: () => import("@/views/operate-manage/records/index.vue"),
        hidden: true,
        meta: { title: "超限记录", icon: "look" },
      },
      // {
      //   path: "/operate-center/channel-station/list",
      //   component: () =>
      //     import("@/views/operate-manage/channelStation/index.vue"),
      //   hidden: true,
      //   meta: { title: "单渠道单站利润率亏损限额", icon: "look" },
      // },
      // {
      //   path: "/operation-center/hcz/record-mark",
      //   component: () =>
      //     import("@/views/operate-manage/hcz/record-mark/index.vue"),
      //   hidden: true,
      //   meta: { title: "竞品频次打标记录", icon: "look" },
      // },
      {
        path: "/operation-center/hcz/record-mark-create",
        component: () =>
          import("@/views/operate-manage/hcz/record-mark-create/index.vue"),
        hidden: true,
        meta: { title: "频次打标配置", icon: "look" },
      },
      // {
      //   path: "/operation-center/hcz/record-down",
      //   component: () =>
      //     import("@/views/operate-manage/hcz/record-down/index.vue"),
      //   hidden: true,
      //   meta: { title: "站联露出率下滑记录", icon: "look" },
      // },
      // {
      //   path: "/operation-center/hcz/hcz-black",
      //   component: () =>
      //     import("@/views/operate-manage/hcz/hcz-black/index.vue"),
      //   hidden: true,
      //   meta: { title: "好车主抓价黑名单", icon: "look" },
      // },
      // {
      //   path: "/operation-center/hcz/hcz-only",
      //   component: () =>
      //     import("@/views/operate-manage/hcz/hcz-only/index.vue"),
      //   hidden: true,
      //   meta: { title: "好车主独家站", icon: "look" },
      // },
      {
        path: "/operation-center/hll/record-mark",
        component: () =>
          import("@/views/operate-manage/hll/record-mark/index.vue"),
        hidden: true,
        meta: { title: "频次打标记录", icon: "look" },
      },
      {
        path: "/operation-center/hll/record-mark-create",
        component: () =>
          import("@/views/operate-manage/hll/record-mark-create/index.vue"),
        hidden: true,
        meta: { title: "频次打标配置", icon: "look" },
      },
      {
        path: "/operation-center/hll/record-down",
        component: () =>
          import("@/views/operate-manage/hll/record-down/index.vue"),
        hidden: true,
        meta: { title: "站联露出率下滑记录", icon: "look" },
      },
      {
        path: "/operation-center/hll/hll-black",
        component: () =>
          import("@/views/operate-manage/hll/hll-black/index.vue"),
        hidden: true,
        meta: { title: "货拉拉抓价黑名单", icon: "look" },
      },
      {
        path: "/operation-center/hll/hll-only",
        component: () =>
          import("@/views/operate-manage/hll/hll-only/index.vue"),
        hidden: true,
        meta: { title: "货拉拉独家站", icon: "look" },
      },
    ],
  },
];

export default operate_manage_router_config;
