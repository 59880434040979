import Layout from "@/layout";

const report_forms_router_config = [
  {
    path: "/report-forms",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/report-forms/station-zk",
      //   component: () => import("@/views/report-forms/station-zk/index.vue"),
      //   hidden: true,
      //   meta: { title: "油站折扣", icon: "list" },
      // },
      // {
      //   path: "/report-forms/station-jl",
      //   component: () => import("@/views/report-forms/station-jl/index.vue"),
      //   hidden: true,
      //   meta: { title: "油站净利", icon: "list" },
      // },
      // {
      //   path: "/report-forms/order-jl",
      //   component: () => import("@/views/report-forms/order-jl/index.vue"),
      //   hidden: true,
      //   meta: { title: "订单净利", icon: "list" },
      // },
      // {
      //   path: "/report-forms/order-ml",
      //   component: () => import("@/views/report-forms/order-ml/index.vue"),
      //   hidden: true,
      //   meta: { title: "订单毛利", icon: "list" },
      // },
      {
        path: "/report-forms/station-ml",
        component: () => import("@/views/report-forms/station-ml/index.vue"),
        hidden: true,
        meta: { title: "油站毛利", icon: "list" },
      },
      {
        path: "/report-forms/report-qd",
        component: () => import("@/views/report-forms/report-qd/index.vue"),
        hidden: true,
        meta: { title: "渠道报表", icon: "list" },
      },
      {
        path: "/report-forms/report-fs",
        component: () => import("@/views/report-forms/report-fs/index.vue"),
        hidden: true,
        meta: { title: "分省报表", icon: "list" },
      },
      {
        path: "/report-forms/report-yz",
        component: () => import("@/views/report-forms/report-yz/index.vue"),
        hidden: true,
        meta: { title: "油站报表", icon: "list" },
      },
      {
        path: "/report-forms/report-qy",
        component: () => import("@/views/report-forms/report-qy/index.vue"),
        hidden: true,
        meta: { title: "区域经理报表", icon: "list" },
      },
      {
        path: "/report-forms/report-yw",
        component: () => import("@/views/report-forms/report-yw/index.vue"),
        hidden: true,
        meta: { title: "业务经理报表", icon: "list" },
      },
    ],
  },
];
export default report_forms_router_config;
