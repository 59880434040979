// 数据控制，用于部分页面进行假数据展示：加油站列表
export const isFeData = false;
// 消费返利：预付金额=账户余额、预付优惠=预付金额*优惠比例
// 充返：账户余额=预付余额+预付优惠、预付优惠=预付金额*优惠比例

// 油站性质(0集团/1国有/2私有/3连锁)
export const natureConfig = [
  {
    label: "集团",
    value: 0,
  },
  {
    label: "国有",
    value: 1,
  },
  {
    label: "私有",
    value: 2,
  },
  {
    label: "连锁",
    value: 3,
  },
];

// 油站品牌(0石油/1石化/2大桥石化/3壳牌/4民营)
export const brandConfig = [
  {
    label: "石油",
    value: 0,
  },
  {
    label: "石化",
    value: 1,
  },
  {
    label: "大桥石化",
    value: 2,
  },
  {
    label: "壳牌",
    value: 3,
  },
  {
    label: "民营",
    value: 4,
  },
];
// 油站类型（0一类站1二类站2三类站）
export const typeConfig = [
  {
    label: "一类油站",
    value: 0,
  },
  {
    label: "二类油站",
    value: 1,
  },
  {
    label: "三类油站",
    value: 2,
  },
];
// 油站属性（0汽油1柴油2油气混合）
export const statsConfig = [
  {
    label: "汽油",
    value: 0,
  },
  {
    label: "柴油",
    value: 1,
  },
  {
    label: "油气混合",
    value: 2,
  },
];
// 开票周期（0周开/1半月开/2月开/3季度开）
export const cycleConfig = [
  {
    label: "周开",
    value: 0,
  },
  {
    label: "半月开",
    value: 1,
  },
  {
    label: "月开",
    value: 2,
  },
  {
    label: "季度开",
    value: 3,
  },
];
// 结算方式 （0 D+1 /  1   预付）
export const wayConfig = [
  {
    label: "D+1",
    value: 0,
  },
  {
    label: "预付",
    value: 1,
  },
];
// 优惠方式（0充返/1消费返）
export const settlementConfig = [
  {
    label: "充返",
    value: 0,
  },
  {
    label: "消费返",
    value: 1,
  },
];
// 状态（0谈判中/1谈判成功/2已签约/3已上架）
export const statusConfig = [
  {
    label: "谈判中",
    value: 0,
  },
  {
    label: "谈判成功",
    value: 1,
  },
  {
    label: "已签约",
    value: 2,
  },
  {
    label: "已上架",
    value: 3,
  },
];

// 设备类型
export const deviceTypeConfig = [
  {
    label: "打印机",
    value: 1,
  },
  {
    label: "手持POS机",
    value: 2,
  },
  {
    label: "手持小票打印机",
    value: 3,
  },
];
// 打印机状态 0：待录入 1：录入失败；2:录入成功；
export const statusListConfig = ["待录入", "录入失败", "录入成功"];

// 国标价管理查询地区只有31个地区
export const oli_position = [
  {
    label: "全部",
    value: "",
  },
  {
    label: "北京",
    value: "北京",
  },
  {
    label: "上海",
    value: "上海",
  },
  {
    label: "江苏",
    value: "江苏",
  },
  {
    label: "天津",
    value: "天津",
  },
  {
    label: "重庆",
    value: "重庆",
  },
  {
    label: "江西",
    value: "江西",
  },
  {
    label: "辽宁",
    value: "辽宁",
  },
  {
    label: "安徽",
    value: "安徽",
  },
  {
    label: "内蒙古",
    value: "内蒙古",
  },
  {
    label: "福建",
    value: "福建",
  },
  {
    label: "宁夏",
    value: "宁夏",
  },
  {
    label: "甘肃",
    value: "甘肃",
  },
  {
    label: "青海",
    value: "青海",
  },
  {
    label: "广东",
    value: "广东",
  },
  {
    label: "山东",
    value: "山东",
  },
  {
    label: "广西",
    value: "广西",
  },
  {
    label: "山西",
    value: "山西",
  },
  {
    label: "贵州",
    value: "贵州",
  },
  {
    label: "陕西",
    value: "陕西",
  },
  {
    label: "海南",
    value: "海南",
  },
  {
    label: "四川",
    value: "四川",
  },
  {
    label: "河北",
    value: "河北",
  },
  {
    label: "西藏",
    value: "西藏",
  },
  {
    label: "河南",
    value: "河南",
  },
  {
    label: "新疆",
    value: "新疆",
  },
  {
    label: "黑龙江",
    value: "黑龙江",
  },
  {
    label: "吉林",
    value: "吉林",
  },
  {
    label: "云南",
    value: "云南",
  },
  {
    label: "湖北",
    value: "湖北",
  },
  {
    label: "浙江",
    value: "浙江",
  },
  {
    label: "湖南",
    value: "湖南",
  },
];

// 油品/油号
export const oli_config = [
  {
    value: "",
    label: "全部",
    children: [
      {
        value: "",
        label: "全部",
      },
    ],
  },
  {
    value: "1",
    label: "汽油",
    children: [
      {
        value: "",
        label: "全部",
      },
      {
        value: "89",
        label: "89#",
      },
      {
        value: "90",
        label: "90#",
      },
      {
        value: "92",
        label: "92#",
      },
      {
        value: "93",
        label: "93#",
      },
      {
        value: "95",
        label: "95#",
      },
      {
        value: "97",
        label: "97#",
      },
      {
        value: "98",
        label: "98#",
      },
      {
        value: "101",
        label: "101#",
      },
      {
        value: "103",
        label: "103#",
      },
      {
        value: "68",
        label: "68#",
      },
    ],
  },
  {
    value: "2",
    label: "柴油",
    children: [
      {
        value: "",
        label: "全部",
      },
      {
        value: "-40",
        label: "-40#",
      },
      {
        value: "-35",
        label: "-35#",
      },
      {
        value: "-30",
        label: "-30#",
      },
      {
        value: "-20",
        label: "-20#",
      },
      {
        value: "-10",
        label: "-10#",
      },
      {
        value: "-1",
        label: "国四0",
      },
      {
        value: "0",
        label: "0#",
      },
    ],
  },
  {
    value: "3",
    label: "天然气",
    children: [
      {
        value: "",
        label: "全部",
      },
      {
        value: "3",
        label: "天然气",
      },
    ],
  },
];

let oli_config_base = JSON.parse(JSON.stringify(oli_config));
oli_config_base = oli_config_base.slice(1).map((item) => {
  item.children = item.children.slice(1);
  return item;
});
export const oli_config2 = oli_config_base;

export const payment_days_config = [
  {
    value: 0,
    label: "D+1",
  },
  {
    value: 1,
    label: "D+0",
  },
];

export const make_invoice_config = [
  {
    value: 0,
    label: "不开票",
  },
  {
    value: 1,
    label: "油站开票",
  },
  {
    value: 2,
    label: "线上开票",
  },
];

// 启用状态
export const enable_state_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 0,
    label: "启用",
  },
  {
    value: 1,
    label: "停用",
  },
];

// 申请列表-审核状态
export const examine_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "待提交",
  },
  {
    value: 2,
    label: "待审核",
  },
  {
    value: 3,
    label: "已撤回",
  },
  {
    value: 4,
    label: "已通过",
  },
  {
    value: 5,
    label: "已驳回",
  },
];
export const examine_status_config2 = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 4,
    label: "已通过",
  },
  {
    value: 5,
    label: "已驳回",
  },
];
// 申请列表-审核类型
export const examine_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "创建加油站",
  },
  {
    value: 2,
    label: "修改基本信息",
  },
  {
    value: 3,
    label: "换证",
  },
  {
    value: 4,
    label: "修改账户信息",
  },
  {
    value: 5,
    label: "修改结算及优惠信息",
  },
  {
    value: 6,
    label: "添加合同信息",
  },
  {
    value: 7,
    label: "合同续签",
  },
  {
    value: 8,
    label: "禁用",
  },
  {
    value: 9,
    label: "合同补签",
  },
  {
    value: 10,
    label: "修改归属商户",
  },
];

// 默认初始判断列表
export const apply_type_list = [
  "isAptitude",
  "isBasic",
  "isAccount",
  "isSettle",
  "isContract",
  "isContractRenew",
  "", //站位
  "isContractReplenishment",
];

// 服务商列表
export const service_mode_config = [
  {
    value: 1,
    label: "油品发票模式",
  },
  {
    value: 2,
    label: "服务费发票模式",
  },
];

// 服务商审核类型
export const apply_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "创建服务商",
  },
  {
    value: 2,
    label: "修改账户信息",
  },
  {
    value: 3,
    label: "补充合同",
  },
  {
    value: 4,
    label: "续签合同",
  },
  {
    value: 5,
    label: "禁用",
  },
];

// 服务商审核状态
export const apply_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "待审核",
  },
  {
    value: 2,
    label: "已撤回",
  },
  {
    value: 3,
    label: "已通过",
  },
  {
    value: 4,
    label: "已驳回",
  },
];
// 服务商-服务模式
export const apply_service_mode_config = [
  {
    value: 1,
    label: "油品发票模式",
  },
  {
    value: 2,
    label: "服务费发票模式",
  },
];

// 渠道-accessMode
export const channel_access_mode_config = [
  {
    value: "ARRIVE_OIL",
    label: "到站加油",
  },
  {
    value: "VERI_COUPON_CODE",
    label: "核销券码",
  },
];

// 渠道-applyType
export const channel_apply_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: "CREATE_CHANNEL",
    label: "创建渠道",
  },
  {
    value: "BASIC_INFO",
    label: "基本信息",
  },
  {
    value: "THIRD_PART_INFO",
    label: "支付平台",
  },
  {
    value: "SERVICE",
    label: "修改服务商",
  },
  {
    value: "COOPERATE_MODE",
    label: "修改合作模式",
  },
  {
    value: "RENEWAL_CONTRACT",
    label: "续签合同",
  },
  {
    value: "CHANNEL_ENABLE",
    label: "修改补充协议",
  },
  {
    value: "UPDATE_SUPPLENMENT_AGREEMENT",
    label: "启用渠道",
  },
  {
    value: "CHANNEL_DISABLE",
    label: "禁用渠道",
  },
  {
    value: "ACCOUNT_UPDATE",
    label: "修改公户",
  },
];

// 渠道-serviceMode
export const channel_service_mode_config = [
  {
    value: "OILS_INVOICE",
    label: "油品发票模式",
  },
  {
    value: "SREVICE_FEE",
    label: "服务费发票模式",
  },
];

// 渠道审核状态
export const channel_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: "AUDIT",
    label: "待审核",
  },
  {
    value: "PASSED",
    label: "已通过",
  },
  {
    value: "REJECTED",
    label: "已驳回",
  },
  {
    value: "REVOKED",
    label: "已撤销",
  },
];

// 发布结算方式
export const settle_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 0,
    label: "预付",
  },
  {
    value: 1,
    label: "后结算",
  },
];

// 发布审核-审核类型
export const publish_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "新增发布",
  },
  {
    value: 2,
    label: "修改发布",
  },
  {
    value: 3,
    label: "取消发布",
  },
];

// 发布审核状态
export const publish_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "待审核	",
  },
  {
    value: 2,
    label: "已撤回",
  },
  {
    value: 3,
    label: "已通过	",
  },
  {
    value: 4,
    label: "已驳回",
  },
];

// 国标价 审核类型
export const nation_apply_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: "GB_CREATE",
    label: "新建国标价",
  },
  {
    value: "GB_UPDATE",
    label: "修改国标价",
  },
];

// 国标价 审核类型
export const nation_effective_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: "AUDIT",
    label: "待审核",
  },
  {
    value: "PASSED",
    label: "已通过",
  },
  {
    value: "REJECTED",
    label: "已驳回",
  },
  {
    value: "REVOKED",
    label: "已撤销",
  },
];

// 订单 支付方式
export const order_payment_method_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "支付宝",
  },
  {
    value: 2,
    label: "微信",
  },
  {
    value: 3,
    label: "银行卡",
  },
];

// 订单 状态
export const order_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: "UNPAY",
    label: "待支付",
  },
  {
    value: "PAYSUCCESS",
    label: "已支付",
  },
  {
    value: "REFUNDING",
    label: "退款中",
  },
  {
    value: "REFUND",
    label: "已退款",
  },
  {
    value: "CLOSED",
    label: "已取消",
  },
];

// 油枪价审核类型
export const oil_review_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "创建油枪价",
  },
  {
    value: 2,
    label: "修改油枪价",
  },
  {
    value: 3,
    label: "开启/关闭油枪价",
  },
];

// 油枪价审核状态
export const oil_review_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "待审核",
  },
  {
    value: 2,
    label: "已撤回",
  },
  {
    value: 3,
    label: "已通过",
  },
  {
    value: 4,
    label: "已驳回",
  },
];

// 活动
export const activity_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "满减",
  },
  {
    value: 2,
    label: "折扣",
  },
  {
    value: 3,
    label: "立减",
  },
  {
    value: 4,
    label: "区间",
  },
];
// 活动状态
export const activity_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 0,
    label: "停用",
  },
  {
    value: 1,
    label: "启用",
  },
];

// 门槛因子
export const activity_divisor_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "订单金额",
  },
  {
    value: 2,
    label: "加油数量",
  },
];

// 区间关系：1-互斥,2-循环,3-组合
export const activity_section_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "互斥",
  },
  // {
  //   value: 2,
  //   label: "循环",
  // },
  // {
  //   value: 3,
  //   label: "组合",
  // },
];

// 活动申请列表-审核状态
export const activity_apply_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "待审核",
  },
  {
    value: 2,
    label: "已撤回",
  },
  {
    value: 3,
    label: "已通过",
  },
  {
    value: 4,
    label: "已驳回",
  },
];

// 活动申请列表-审核类型
export const activity_apply_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "创建活动",
  },
  {
    value: 2,
    label: "启用活动",
  },
  {
    value: 3,
    label: "停用活动",
  },
  {
    value: 4,
    label: "发布活动",
  },
  {
    value: 5,
    label: "修改发布",
  },
  {
    value: 6,
    label: "活动失效",
  },
];

// 发布活动
export const release_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 3,
    label: "未开始",
  },
  {
    value: 4,
    label: "进行中",
  },
  {
    value: 5,
    label: "已结束",
  },
];

// 发布活动 客户人群
export const publish_custom_type_config = [
  {
    value: 1,
    label: "全量客户",
  },
  {
    value: 2,
    label: "新人客户",
  },
];

// 发布活动 标签类型:1-自定义类,2-活动促销类,3-系统促销类,4-服务类
export const publish_label_type_config = [
  {
    value: 1,
    label: "自定义类",
  },
  {
    value: 2,
    label: "活动促销类",
  },
  {
    value: 3,
    label: "系统促销类",
  },
  {
    value: 4,
    label: "服务类",
  },
];

// 价格类型:1-国标价(折扣),2-油枪价(折扣),3-渠道价(金额)
export const release_station_price_type_config = [
  {
    value: 1,
    label: "国标价",
  },
  {
    value: 2,
    label: "油枪价",
  },
  {
    value: 3,
    label: "渠道价",
  },
];
// 1-国标价,2-油枪价,3-渠道价,4-优惠价
export const release_station_price_type_config2 = [
  {
    value: 1,
    label: "国标价",
  },
  {
    value: 2,
    label: "油枪价",
  },
  {
    value: 3,
    label: "渠道价",
  },
  {
    value: 4,
    label: "优惠价",
  },
];

// 优惠门槛
// 优惠门槛:1-平行优惠,2-递进优惠
export const release_prefer_type_config = [
  {
    value: 1,
    label: "平行优惠",
  },
  {
    value: 2,
    label: "递进优惠",
  },
];

export const station_activity_source_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "平台",
  },
  {
    value: 2,
    label: "渠道",
  },
  {
    value: 3,
    label: "油站",
  },
];

export const station_publish_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 3,
    label: "未开始",
  },
  {
    value: 4,
    label: "进行中",
  },
  {
    value: 5,
    label: "已结束",
  },
];

export const account_apply_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "待审核",
  },
  {
    value: 2,
    label: "已撤回",
  },
  {
    value: 3,
    label: "已通过",
  },
  {
    value: 4,
    label: "已驳回",
  },
];

export const account_apply_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "配置结算及优惠类型",
  },
  {
    value: 2,
    label: "修改结算及优惠类型",
  },
  {
    value: 3,
    label: "配置充值返现优惠",
  },
  {
    value: 4,
    label: "配置消费返现优惠",
  },
];

// 财务中心-状态 提现状态：1-提现中, 2-成功, 3-失败
export const account_review_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "提现中",
  },
  {
    value: 2,
    label: "成功",
  },
  {
    value: 3,
    label: "失败",
  },
];
// 财务中心-状态 提现状态：1-提现中, 2-成功, 3-失败
export const account_review_status_config2 = [
  {
    value: 1,
    label: "提现中",
  },
  {
    value: 2,
    label: "成功",
  },
  {
    value: 3,
    label: "失败",
  },
];

// 充值-业务类型:1-充值,2-交易,3-退款,4-保证金,5-充值回收 6-调整订单
export const account_front_business_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "充值",
  },
  {
    value: 2,
    label: "交易",
  },
  {
    value: 3,
    label: "退款",
  },
  {
    value: 4,
    label: "保证金",
  },
  {
    value: 5,
    label: "充值回收",
  },
  {
    value: 6,
    label: "调整订单",
  },
];

// 充值总账户 业务类型:1-充值,2-保证金,3-充值回收
export const account_front_all_business_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "充值",
  },
  {
    value: 2,
    label: "保证金",
  },
  {
    value: 3,
    label: "充值回收",
  },
];

// 总账户资金明细 业务名称:1-总账户充值,2-保证金,3-总账户充值返现,4-子账户充值,5-总账户充值回收,6-子账户充值回收
export const account_front_all_business_name_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "总账户充值",
  },
  {
    value: 2,
    label: "保证金",
  },
  {
    value: 3,
    label: "总账户充值返现",
  },
  {
    value: 4,
    label: "子账户充值",
  },
  {
    value: 5,
    label: "总账户充值回收",
  },
  {
    value: 6,
    label: "子账户充值回收",
  },
];

// 充值-业务名称:1-油站充值,2-充值返现,3-消费返现,4-加油交易,5-加油退款
export const account_front_business_name_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "油站充值",
  },
  {
    value: 2,
    label: "充值返现",
  },
  {
    value: 3,
    label: "消费返现",
  },
  {
    value: 4,
    label: "加油交易",
  },
  {
    value: 5,
    label: "加油退款",
  },
];
// 油站充值子账户油站名称下拉
// 业务名称:1-子账户充值,2-保证金,3-加油交易,4-加油退款,5-子账户消返充值,6-子账户充值回收,7-子账户消返充值回收 8-调整订单
export const account_front_business_name_son_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "子账户充值",
  },
  {
    value: 2,
    label: "保证金",
  },
  {
    value: 3,
    label: "加油交易",
  },
  {
    value: 4,
    label: "加油退款",
  },
  {
    value: 5,
    label: "子账户消返充值",
  },
  {
    value: 6,
    label: "子账户充值回收",
  },
  {
    value: 7,
    label: "子账户消返充值回收",
  },
  {
    value: 8,
    label: "调整订单",
  },
];
// 子账户 充值记录使用 业务名称:1-子账户充值,2-保证金,3-子账户消返充值,4-子账户充值回收,5-子账户消返充值回收
export const account_front_business_name_config2 = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "子账户充值",
  },
  {
    value: 2,
    label: "保证金",
  },
  {
    value: 3,
    label: "子账户消返充值",
  },
  {
    value: 4,
    label: "子账户充值回收",
  },
  {
    value: 5,
    label: "子账户消返充值回收",
  },
];

// 充值总账户 业务名称:1-总账户充值,2-保证金,3-总账户充值返现,4-总账户充值回收
export const account_front_all_business_name_config2 = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "总账户充值",
  },
  {
    value: 2,
    label: "保证金",
  },
  {
    value: 3,
    label: "总账户充值返现",
  },
  {
    value: 3,
    label: "总账户充值回收",
  },
];

// 充值-	收支类型:1-收入,2-支出
export const account_front_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "收入",
  },
  {
    value: 2,
    label: "支出",
  },
];

// 后结算 业务类型 1:交易 2:退款 3:提现 4 引流服务费 5 引流服务费退回
export const account_back_business_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "交易",
  },
  {
    value: 2,
    label: "退款",
  },
  {
    value: 3,
    label: "提现",
  },
  {
    value: 4,
    label: "引流服务费",
  },
  {
    value: 5,
    label: "引流服务费退回",
  },
  {
    value: 6,
    label: "提现手续费",
  },
  {
    value: 7,
    label: "提现手续费退回",
  },
];

// 充值-业务类型二级分类（ 1 加油站交易 2 加油站退款 3 加油站提现 4 引流服务费 5 引流服务费退回
export const account_back_business_name_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "加油交易",
  },
  {
    value: 2,
    label: "加油退款",
  },
  {
    value: 3,
    label: "油款提现",
  },
  {
    value: 4,
    label: "引流服务费",
  },
  {
    value: 5,
    label: "引流服务费退回",
  },
  {
    value: 6,
    label: "撤销提现",
  },
  {
    value: 7,
    label: "油款提现(已撤销)",
  },
  {
    value: 8,
    label: "提现手续费",
  },
  {
    value: 9,
    label: "提现手续费退回",
  },
];

// 打印机列表
// 绑定状态 // 1:已绑定；2:已解绑
export const printer_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 2,
    label: "已绑定",
  },
  {
    value: 1,
    label: "未绑定",
  },
];
// 打印机列表-在线状态
export const printer_line_status_config = [
  {
    value: "",
    label: "无接口-全部",
  },
  {
    value: 1,
    label: "在线",
  },
  {
    value: 2,
    label: "离线",
  },
];

// 商户中心合同
export const status_list_config = [
  {
    label: "失效",
    value: 0,
  },
  {
    label: "生效中",
    value: 1,
  },
  {
    label: "待生效",
    value: 2,
  },
];

// 充值返现优惠规则状态
export const gas_station_ad_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    label: "未开始",
    value: 1,
  },
  {
    label: "进行中",
    value: 2,
  },
  {
    label: "已结束",
    value: 3,
  },
];

// 账户中心 总账户 账户状态
export const accound_back_all_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    label: "禁用",
    value: 0,
  },
  {
    label: "启用",
    value: 1,
  },
];

// 账户中心 总账户 showFlag 油站端是否展示:0-否,1-是
export const show_flag_all_status_config = [
  {
    label: "否",
    value: 0,
  },
  {
    label: "是",
    value: 1,
  },
];

// 油站对账状态:0-未确认,1-已确认
export const business_status_all_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    label: "未确认",
    value: 0,
  },
  {
    label: "已确认",
    value: 1,
  },
];

// 订单列表 开票状态
export const invoice_flag_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    label: "未开票",
    value: 0,
  },
  {
    label: "已开票",
    value: 1,
  },
];

// 加油站列表 油站发布状态 发布状态(0-未发布 1-已发布)
export const gas_station_publish_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    label: "未发布",
    value: 0,
  },
  {
    label: "已发布",
    value: 1,
  },
];
// 对公账户我的申请 审核状态（1待审核、2已撤回、3已通过、4已驳回）
export const merchant_account_apply_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    label: "待审核",
    value: 1,
  },
  {
    label: "已撤回",
    value: 2,
  },
  {
    label: "已通过",
    value: 3,
  },
  {
    label: "已驳回",
    value: 4,
  },
];
// 对公账户我的申请 审核类型 1、修改对公账户信息
export const merchant_account_apply_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    label: "修改对公账户信息",
    value: 1,
  },
];

// 消返明细-返现状态 返现状态:1-未返现,2-已返现,3-已取消,4-已撤销
export const account_front_list_detail_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    label: "未返现",
    value: 1,
  },
  {
    label: "已返现",
    value: 2,
  },
  {
    label: "已取消",
    value: 3,
  },
  {
    label: "已撤销",
    value: 4,
  },
];
// 订单 状态 继承而来
export const account_front_order_status_config = [
  // {
  //   value: "",
  //   label: "全部",
  // },
  // {
  //   value: "UNPAY",
  //   label: "待支付",
  // },
  {
    value: "PAYSUCCESS",
    label: "已支付",
  },
  {
    value: "REFUNDING",
    label: "退款中",
  },
  {
    value: "REFUND",
    label: "已退款",
  },
  // {
  //   value: "CLOSED",
  //   label: "已取消",
  // },
];

// 支付平台账户审核状态 1 申请审核中 2 审核已驳回 3 协议待签署 4 业务开通中 5 申请已完成
export const register_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    label: "申请审核中",
    value: 1,
  },
  {
    label: "审核已驳回",
    value: 2,
  },
  {
    label: "协议待签署",
    value: 3,
  },
  {
    label: "业务开通中",
    value: 4,
  },
  {
    label: "申请已完成",
    value: 5,
  },
];

export const publish_increase_radio_config = [
  {
    label: "按金额",
    value: 0,
  },
  {
    label: "按比例",
    value: 1,
  },
  {
    label: "结算价透传",
    value: 2,
  },
  {
    label: "油站折扣价透传",
    value: 3,
  },
];
// 渠道对账状态:0-未对账,1-已对账->channelBillStatus
export const channel_bill_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    label: "未对账",
    value: 0,
  },
  {
    label: "已对账",
    value: 1,
  },
];

// 对账结果:0-不通过,1-通过
export const channel_bill_flag_config = [
  {
    value: "",
    label: "全部",
  },
  {
    label: "不通过",
    value: 0,
  },
  {
    label: "通过",
    value: 1,
  },
];

// 是否确认对账:0-否,1-是
export const channel_confirm_flag_config = [
  {
    value: "",
    label: "全部",
  },
  {
    label: "否",
    value: 0,
  },
  {
    label: "是",
    value: 1,
  },
];

// 退款发起方：1-渠道，0-平台
export const refund_audit_apply_source_config = [
  {
    label: "平台侧",
    value: 0,
  },
  {
    label: "渠道侧",
    value: 1,
  },
  {
    label: "油站侧",
    value: 2,
  },
  {
    label: "供应商侧",
    value: 3,
  },
];

// 审核状态:0-通过,1-平台审核未通过,2-平台待审核,3-供应商审核未通过,4-供应商待审核
export const refund_audit_apply_status_config = [
  {
    label: "通过",
    value: 0,
  },
  {
    label: "平台审核未通过",
    value: 1,
  },
  {
    label: "平台待审核",
    value: 2,
  },
  {
    label: "供应商审核未通过",
    value: 3,
  },
  {
    label: "供应商待审核",
    value: 4,
  },
];

// 渠道对公账户 申请类型（1新增对公账户、2修改对公账户信息）
export const merchant_manage_account_apply_type_config = [
  {
    label: "全部",
    value: "",
  },
  {
    label: "新增对公账户",
    value: 1,
  },
  {
    label: "修改对公账户信息",
    value: 2,
  },
];

// 渠道垫资资金明细 业务类型:1-还款,2-垫资
export const account_manage_channel_business_type_config = [
  {
    label: "全部",
    value: "",
  },
  {
    label: "还款",
    value: 1,
  },
  {
    label: "垫资",
    value: 2,
  },
];
// 渠道垫资资金明细 业务名称:1-还款,2-撤销还款,3-垫资,4-撤销垫资
export const account_manage_channel_business_name_config = [
  {
    label: "全部",
    value: "",
  },
  {
    label: "还款",
    value: 1,
  },
  {
    label: "撤销还款",
    value: 2,
  },
  {
    label: "垫资",
    value: 3,
  },
  {
    label: "撤销垫资",
    value: 4,
  },
];

// 垫资记录 业务类型:1-垫资,2-撤销垫资
export const records_dz_business_type_config = [
  {
    label: "全部",
    value: "",
  },
  {
    label: "垫资",
    value: 1,
  },
  {
    label: "撤销垫资",
    value: 2,
  },
];
// 垫资记录 渠道垫资对账状态:0-未对账,1-已对账
export const records_dz_channel_bill_status_config = [
  {
    label: "全部",
    value: "",
  },
  {
    label: "未对账",
    value: 0,
  },
  {
    label: "已对账",
    value: 1,
  },
];
// 垫资记录 还款状态:0-未还款,1-已还款
export const records_dz_repaid_status_config = [
  {
    label: "全部",
    value: "",
  },
  {
    label: "未还款",
    value: 0,
  },
  {
    label: "已还款",
    value: 1,
  },
];
// 垫资记录 业务类型:1-还款,2-撤销还款
export const records_hk_business_type_config = [
  {
    label: "全部",
    value: "",
  },
  {
    label: "还款",
    value: 1,
  },
  {
    label: "撤销还款",
    value: 2,
  },
];

// 业务类型 1-渠道佣金，2-司机服务费（渠道），3-提现，4-渠道活动佣金
export const account_monage_channel_business_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "渠道佣金",
  },
  {
    value: 2,
    label: "司机服务费（渠道）",
  },
  {
    value: 3,
    label: "提现",
  },
  {
    value: 4,
    label: "渠道活动佣金",
  },
];
//业务名称：1-渠道佣金 2-司机服务费（渠道） 3-提现 4-渠道佣金退回 5-司机服务费（渠道）退回 6-撤销提现 7-渠道活动佣金 8-渠道活动佣金退回
export const account_monage_channel_business_name_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "渠道佣金",
  },
  {
    value: 2,
    label: "司机服务费（渠道）",
  },
  {
    value: 3,
    label: "提现",
  },
  {
    value: 4,
    label: "渠道佣金退回",
  },
  {
    value: 5,
    label: "司机服务费（渠道）退回",
  },
  {
    value: 6,
    label: "撤销提现",
  },
  {
    value: 7,
    label: "渠道活动佣金",
  },
  {
    value: 8,
    label: "渠道活动佣金退回",
  },
];

// 服务费类型（1、订单金额 2、油站应结算金额）
export const gas_stations_service_charge_type = [
  {
    value: 1,
    label: "订单金额",
  },
  {
    value: 2,
    label: "油站应结算金额",
  },
];
// 结算时间：0-周期，1-实时
export const account_settlement_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 0,
    label: "周期",
  },
  {
    value: 1,
    label: "实时",
  },
];

// 提现方式（1、分账提现 1、企业提现 2、人工打款）
export const account_withdraw_type_config = [
  {
    value: 1,
    label: "分账提现",
  },
  {
    value: 2,
    label: "企业提现",
  },
  {
    value: 3,
    label: "人工打款",
  },
];

// 业务类型:1-其他,2-提现
export const yeepay_business_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "其他",
  },
  {
    value: 2,
    label: "提现",
  },
];
// 审核状态（1、待审核、2、通过、3不通过）
export const record_apply_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 2,
    label: "已通过",
  },
  {
    value: 3,
    label: "已驳回",
  },
];
// 	财务是否标记状态（0、未标记 、1、标记）
export const finance_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 0,
    label: "未标记",
  },
  {
    value: 1,
    label: "已标记",
  },
];

// 结算及优惠信息有效时间
export const time_list_config = [
  {
    value: 1,
    label: "每日",
  },
  {
    value: 2,
    label: "每周",
  },
  {
    value: 3,
    label: "每月",
  },
];
// 每周
const timeMonthText = ["一", "二", "三", "四", "五", "六", "日"];
export const time_month_list_config = [];
for (let i = 0; i < 7; i++) {
  const obj = {
    value: i + 1,
    label: `周${timeMonthText[i]}`,
  };
  time_month_list_config.push(obj);
}
// 每日
export const time_day_list_config = [];
for (let i = 1; i <= 31; i++) {
  const obj = {
    value: i,
    label: i,
  };
  time_day_list_config.push(obj);
}

// 模板类型:1-好伙伴,2-捷银
export const account_template_type_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "好伙伴",
  },
  {
    value: 2,
    label: "捷银",
  },
];
// 上架状态:0-未上架,1-已上架
export const electron_coupon_publish_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 0,
    label: "未上架",
  },
  {
    value: 1,
    label: "已上架",
  },
  {
    value: 2,
    label: "已下架",
  },
];

// 电子券类型:1-固定金额满减券
export const electron_coupon_coupon_type_config = [
  {
    value: 1,
    label: "固定金额满减券",
  },
];

// 移动端发布状态
export const small_status_config = [
  {
    value: "",
    text: "全部",
  },
  {
    text: "未发布",
    value: 0,
  },
  {
    text: "已发布",
    value: 1,
  },
];

export const account_select_rule_config = [
  {
    value: 1,
    label: "订单金额",
  },
  {
    value: 0,
    label: "油枪价",
  },
];
// 处理状态
export const account_fail_list_status_config = [
  {
    value: "",
    label: "全部",
  },
  {
    label: "待处理",
    value: 1,
  },
  {
    label: "重试中",
    value: 2,
  },
  {
    label: "重试成功",
    value: 3,
  },
  {
    label: "重试失败",
    value: 4,
  },
  {
    label: "人工处理中",
    value: 5,
  },
  {
    label: "人工处理完成",
    value: 6,
  },
  {
    label: "无需处理",
    value: 7,
  },
];
// 是否为独家站（0 疑似独家 1 独家 2 非独家
export const exclusive_flag_config = [
  {
    value: "",
    label: "全部",
  },
  {
    label: "疑似独家",
    value: 0,
  },
  {
    label: "独家",
    value: 1,
  },
  {
    label: "非独家",
    value: 2,
  },
];
